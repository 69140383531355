* {
  margin: 0;
  padding: 0;
}

html, body, #root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  padding: 20vh 0;
}

.header, .footer {
  background-color: lightblue;
}

.footer {
  padding: 20px 0;
  text-align: center;
  margin-top: auto;
}

.header .MuiPaper-root {
  background: transparent!important;
}

.Mui-disabled {
  .MuiStepLabel-labelContainer {
    display: none;
  }
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 992px){
    flex-direction: column;
  }
  @media screen and (max-width: 768px) {
    & > div {
      display: none;
    }
  }
}

.choose {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  & > p {
    font-size: 36px;
    position: relative;
    display: block;
    max-width: 500px;
    width: 100%;
    margin: 0 auto 20px;
    span:first-of-type {
      display: block;
      width: 500px;
      height: 300px;
      background-color: #cacaca;
      margin-bottom: 20px;

      @media screen and (max-width: 600px) {
        max-width: 500px;
        width: 100%;
      }
    }
    span:nth-of-type(2) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }
  input {
    opacity: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    height: 345px;
    top: 0;
    z-index: 1;

    @media screen and (max-width: 600px) {
      max-width: 500px;
      width: 100%;
    }
  }
  label {
    margin: 20px 0;
    cursor: pointer;
    span:first-of-type {
      background-color: #cacaca;
      color: #ffffff;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
    }
    &:hover {
      span {
        background-color: darken(#cacaca, 5);
      }
    }
  }
  &__error {
    color: red;
  }
}

.crop-container {
    width: 500px;
    margin: 0 auto 30px;
    height: 500px;
    position: relative;

  @media screen and (max-width: 600px) {
    max-width: 300px;
    width: 100%;
  }
}

.controls{
  max-width: 300px;
  margin: 10px auto;
}

.button {
  display: flex;
  justify-content: center;
  margin: 20px auto;
;
}

.button .MuiButtonBase-root {
  display: block;
  margin: 20px;
}

.resized {
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  &__row > p {
    flex-basis: 100%;
  }
  &__item {
    margin-right: 20px;
    flex-basis: 48%;
  }
  &__comp {
    flex-direction: column;
  }
  &__comp p {
    display: flex;
    max-width: 300px;
  }
  &__comp p span {
    flex-basis: 50%;
  }
}
